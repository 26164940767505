import { useState, useEffect, useCallback, useMemo } from 'react';

import SubtitlesClient from '../api/subtitlesClient';
import ProjectClient from '../api/projectClient';

const useSilenceCutting = ({ id, updateProject }) => {
	const [silenceRenderId, setSilenceRenderId] = useState(null);
	const [cutSilenceLoader, setCutSilenceLoader] = useState(false);

	const subtitlesClient = useMemo(() => (new SubtitlesClient()), []);
	const projectClient = useMemo(() => (new ProjectClient()), []);

	const addSilenceCutting = useCallback(async () => {
		const res = await subtitlesClient.addSilenceCutting(id);

		if (res.message?.renderId) {
			setCutSilenceLoader(true);
			setSilenceRenderId(res.message?.renderId);
		} else {
			setCutSilenceLoader(false);

			console.log(res);
		}
	}, [id, subtitlesClient]);

	const handleCheckSilenceCutting = useCallback(async () => {
		let res = await subtitlesClient.getSubtitles(silenceRenderId);

		if (res?.currentStatus !== 5) {
			setTimeout(handleCheckSilenceCutting, 5000);
		} else {
			let res = await projectClient.get(id);
			await updateProject(res, true);
			
			setCutSilenceLoader(false);
		}
	}, [subtitlesClient, silenceRenderId, projectClient, id, updateProject]);

	useEffect(() => {
		if (cutSilenceLoader) {
			handleCheckSilenceCutting();
		}
	}, [handleCheckSilenceCutting, cutSilenceLoader]);

	return {
		cutSilenceLoader,
		addSilenceCutting,
	};
};

export default useSilenceCutting;