import { Checkbox } from '../../../components/checkbox/Checkbox';

import { FormControl } from 'react-bootstrap';

function NewProjectAudioUpload({
	preset,
	isUploading,
	customAudioCheckox,
	handleOnChange,
	setAudioFile,
	setCustomAudioCheckox,
}) {
	const handleAudioFileChange = (event) => {
		event.preventDefault();

		const audioFile = event.currentTarget.files;

		setAudioFile(audioFile);
		handleOnChange(audioFile, preset + 1);
	};

	return (
		<>
			<Checkbox
				id="custom-audio"
				text="Добавить свою аудиодорожку"
				value={customAudioCheckox}
				setValue={setCustomAudioCheckox}
				isDisabled={isUploading}
			/>
			{
				customAudioCheckox &&
					<FormControl
						className="mt-2"
						type="file"
						accept="audio/*"
						multiple={false}
						onChange={handleAudioFileChange}
						disabled={isUploading}
						required
					/>
			}
		</>
	);
}

export default NewProjectAudioUpload;
