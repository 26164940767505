import { useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';

import AuthHelper from '../../../helpers/authHelper';

const authHelper = new AuthHelper();

const AuthProtectedRoute = ({ children }) => {
  const token = useSelector((state) => state.auth.jwt);

  if (token && authHelper.checkToken(token)) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const MainProtectedRoute = ({ children }) => {
  const token = useSelector((state) => state.auth.jwt);

  if (!token || !authHelper.checkToken(token)) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const RegisterProtectedRoute = ({ children }) => {
  const [searchParams] = useSearchParams();

  const registerToken = searchParams.get('registerToken');

  if (!registerToken) {
    return <Navigate to="/subscription" replace />;
  }

  return children;
}

export { AuthProtectedRoute, MainProtectedRoute, RegisterProtectedRoute };
