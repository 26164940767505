import './index.scss';

import { useState } from 'react';
import { useTranslation } from "react-i18next";

import { Button } from 'react-bootstrap';
import { FileEarmarkPlus, FileEarmarkCheck, X } from 'react-bootstrap-icons';

const UploadFile = ({
	id,
	// preset,
	handleOnChange,
	handleDeleteFiles,
	errorMessage,
	handleSetErrorMessage,
	isUploading
}) => {
	const [filesPaths, setFilesPaths] = useState({});
	const [isDragging, setIsDragging] = useState(false);
	
	const { t } = useTranslation();

	const validateFiles = (files) => {
		return Array.from(files).every(file => file.type.startsWith('video/'));
	};

	const validateDuplicate = (newFiles) => {
		return Array.from(newFiles).some(file => filesPaths[file.name]);
	};

	const handleDrop = (event) => {
		event.preventDefault();

		if (isUploading) return;
		if (!event.dataTransfer) return;
		
		const newFiles = event.dataTransfer.files;
		
		// if (preset === 0 && newFiles.length > 1) {
		// 	handleSetErrorMessage(prevErrors => ({
		// 		...prevErrors,
		// 		[id]: 'onlyOneVideo'
		// 	}));

		// 	setIsDragging(false);

		// 	return;
		// }

		if (!validateFiles(newFiles)) {
			handleSetErrorMessage(prevErrors => ({
				...prevErrors,
				[id]: 'onlyVideo'
			}));
	
			setIsDragging(false);

			return;
		}

		if (validateDuplicate(newFiles)) {
			handleSetErrorMessage(prevErrors => ({
				...prevErrors,
				[id]: 'duplicateFile'
			}));

			setIsDragging(false);

			return;
		}

		const newFilesPaths = {};

		Array.from(newFiles).forEach((file, i) => {
			newFilesPaths[file.name] = file;
		});

		setFilesPaths((prevFilesPaths) => ({ ...prevFilesPaths, ...newFilesPaths }));
		handleOnChange(newFiles, id);
		setIsDragging(false);
		handleSetErrorMessage(prevErrors => ({
			...prevErrors,
			[id]: ''
		}));
	};

	const handleFiles = (event) => {
		event.preventDefault();

		if (isUploading) return;

		const newFiles = event.currentTarget.files;

		// if (preset === 0 && newFiles.length > 1) {
		// 	handleSetErrorMessage(prevErrors => ({
		// 		...prevErrors,
		// 		[id]: 'onlyOneVideo'
		// 	}));

		// 	setIsDragging(false);

		// 	return;
		// }

		if (!validateFiles(newFiles)) {
			handleSetErrorMessage(prevErrors => ({
				...prevErrors,
				[id]: 'onlyVideo'
			}));

			setIsDragging(false);

			return;
		}

		if (validateDuplicate(newFiles)) {
			handleSetErrorMessage(prevErrors => ({
				...prevErrors,
				[id]: 'duplicateFile'
			}));
		
			setIsDragging(false);

			return;
		}

		const newFilesPaths = {};

		Array.from(newFiles).forEach((file) => {
			newFilesPaths[file.name] = file;
		});

		setFilesPaths((prevFilesPaths) => ({ ...prevFilesPaths, ...newFilesPaths }));
		handleOnChange(newFiles, id);
		handleSetErrorMessage(prevErrors => ({
			...prevErrors,
			[id]: ''
		}));
	};

	const handleRemoveFile = (event, fileName) => {
		event.preventDefault();

		if (isUploading) return;

		setFilesPaths((prevFilesPaths) => {
			const newFilesPaths = { ...prevFilesPaths };
			delete newFilesPaths[fileName];

			return newFilesPaths;
		});
		
		return handleDeleteFiles(fileName, id);
	};

	const handleDragEnter = (event) => {
		event.preventDefault();

		if (isUploading) return;

		setIsDragging(true);
	};

	const handleDragLeave = (event) => {
		event.preventDefault();

		if (isUploading) return;

		const { relatedTarget } = event;

		if (!event.currentTarget.contains(relatedTarget)) {
			setIsDragging(false);
		}
	};

	const handleDragOver = (event) => {
		event.preventDefault();

		if (isUploading) return;
		if (!isDragging) {
			setIsDragging(true);
		}
	};

	return (
		<>
			<input
				id={"file-" + id}
				className="upload"
				type="file"
				accept='video/*'
				multiple
				hidden
				disabled={Object.keys(filesPaths).length > 0}
				onChange={handleFiles}
			/>
			<label
				htmlFor={"file-" + id}
				className={`upload-container ${isUploading ? 'upload-container_disabled' : ''} ${isDragging ? 'upload-container_active' : ''}`}
				onDrop={handleDrop}
				onDragOver={handleDragOver}
				onDragEnter={handleDragEnter}
				onDragLeave={handleDragLeave}
			>
				{
					Object.keys(filesPaths).length > 0 ? (
						<>
							{Object.keys(filesPaths).map((fileName) => (
								<div className="upload-container__info" key={fileName}>
									<FileEarmarkCheck className="upload-container__info-icon" />
									<p className="upload-container__info-text">{fileName}</p>
									<Button
										className="upload-container__info-clear border-0 cursor-pointer p-0"
										variant="transparent"
										disabled={isUploading}
										onClick={(e) => handleRemoveFile(e, fileName)}
									>
										<X width={24} height={24} />
									</Button>
								</div>
							))}
						</>
					) : (
						<div className="upload-container__info upload-container__info_drop" inert="true">
							<FileEarmarkPlus className="upload-container__info-icon" />
							<p className="upload-container__info-text">
								{t('dragAndDrop')}
							</p>
						</div>
					)
				}
			</label>
			{errorMessage && <span className="text-danger d-inline-flex mt-1">{t(errorMessage)}</span>}
		</>
	);
}

export default UploadFile;
