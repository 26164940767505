import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';
import UploadFile from '../../../components/uploadFile';
import RadioInputValidate from '../../../components/input/RadioInputValidate';
import NewProjectAudioUpload from '../newProjectAudioUpload';

import { Trash } from 'react-bootstrap-icons';

function NewProjectUpload({
	projectVideos,
	preset,
	track,
	register,
	errors,
	uploadError,
	errorMessage,
	isUploading,
	setTrack,
	setErrorMessage,
	handleVideoClicked,
	handleOnChange,
	handleRemoveFile,
	handleRemoveVideo,
	handleAddVideo,
}) {
	const { t } = useTranslation();

	const [customAudioCheckox, setCustomAudioCheckox] = useState(false);
	const [previousTrack, setPreviousTrack] = useState(track);
	const [audioFile, setAudioFile] = useState(null);

	const handleAddAudio = () => {
		if (isUploading) return;

		if (!customAudioCheckox) {
			setPreviousTrack(track);
			setTrack(preset + 1);
		} else {
			setTrack(previousTrack);

			if (audioFile) {
				handleRemoveFile(audioFile[0].name, preset + 1);
				setAudioFile(null);
			}
		}

		setCustomAudioCheckox(!customAudioCheckox);
	};

	return (
		<div className="d-flex flex-column gap-3">
			{
				projectVideos.map((v, i) => {
					return (
						<div key={v.camera} className="d-flex flex-column gap-3 bg-white p-3 rounded position-relative">
							<label>{t(v.name)}</label>
							<div className="col-12" onClick={handleVideoClicked}>
								<UploadFile
									id={v.camera}
									// preset={preset}
									handleOnChange={handleOnChange}
									handleDeleteFiles={handleRemoveFile}
									errorMessage={errorMessage[v.camera]}
									handleSetErrorMessage={setErrorMessage}
									isUploading={isUploading}
								/>
							</div>
							{
								!customAudioCheckox &&
									<div className="col-12">
										<RadioInputValidate
											selected={track === v.camera}
											onChange={evt => setTrack(Number(evt.target.value))}
											name="track"
											value={v.camera} 
											register={register}
											errors={errors} 
											validation={{ required: t("needToChoose") }} label={t("audio")}
											disabled={isUploading}
										/>
									</div>
							}
							{
								v.camera > 0 && v.camera === projectVideos.length - 1 &&
									<Button
										className="position-absolute top-0 end-0 p-0 cursor-pointer mt-3 me-3 border-0"
										variant="transparent"
										onClick={() => handleRemoveVideo(v.camera)}
										disabled={isUploading}
									>
										<Trash width={16} height={20} />
									</Button>
							}
						</div>
					);
				})
			}
			{
				uploadError &&
					<span className="text-danger alert alert-danger m-0 text-center">
						{t(uploadError)}
					</span>
			}
			<div className="col-12">
				<NewProjectAudioUpload
					isUploading={isUploading}
					preset={preset}
					customAudioCheckox={customAudioCheckox}
					setAudioFile={setAudioFile}
					setCustomAudioCheckox={handleAddAudio}
					handleOnChange={handleOnChange}
				/>
				{
					preset < 2 &&
						<Button
							variant={"outline-info"}
							className="btn-width w-100 mt-3"
							size='lg'
							type="button"
							onClick={handleAddVideo}
							disabled={isUploading}
						>
							{t("addPlan")}
						</Button>
				}
			</div>
		</div>
	);
}

export default NewProjectUpload;
