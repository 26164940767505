import { Config } from '../config';
import AuthHelper from "../helpers/authHelper";

class UploadClient {
	constructor(url = "storage", chunkSize = 6000000) {
		this.authHelper = new AuthHelper();
		this.url = `${Config.host}/${url}`;
		this.chunkSize = chunkSize;

		this.OnProgress = null;
		this.OnLoad = null;
		this.OnComplete = null;
		this.uploadError = null;
		this.uploadMessage = null;

		this.project = {
			name: '',
			preset: '',
			fileNames: [],
			currentAudioTrack: 0,
			settings: null
		};
	}

	/**
	 * Создает Blob из project для загрузки
	 * 
	 * @return {Blob} Blob, содержащий project в json-формате
	 */
	createProjectBlob() {
		const projectString = JSON.stringify(this.project);
		const projectBytes = new TextEncoder().encode(projectString);
		
		return new Blob([projectBytes], { type: "application/json;charset=utf-8" });
	}

	/**
	 * Разбивает файл на чанки и добавляет их к chunkData
	 * 
	 * @param {FormData} chunkData - объект FormData, куда будут добавлены чанки
	 * @param {File} file - файл, который будет разбит на чанки
	 * @param {number} index - порядковый номер файла
	 */
	appendFileChunks(chunkData, file, index) {
		const chunkCount = Math.ceil(file.size / this.chunkSize);

		for (let i = 0; i < chunkCount; i++) {
			const start = i * this.chunkSize;
			const end = Math.min((i + 1) * this.chunkSize, file.size);
			const chunk = file.slice(start, end);

			if (file.type.startsWith('video/')) {
				chunkData.append('file', chunk, `${index}.raw`);
			} else if (file.type.startsWith('audio/')) {
				chunkData.append('file', chunk, `${index}.wav`);
			}
		}
	}

	/**
	 * Создает FormData для загрузки на сервер
	 * 
	 * @param {File[]} files - массив файлов, которые будут загружены
	 * @returns {FormData} объект FormData для загрузки
	 */
	createFormData(files) {
		const chunkData = new FormData();
		const projectBlob = this.createProjectBlob();

		chunkData.append('project', projectBlob, 'project.json');

		files.forEach((file, index) => {
			this.appendFileChunks(chunkData, file, index);
		});

		return chunkData;
	}

	/**
	 * Callback, вызываемый при изменении прогресса загрузки.
	 * 
	 * @param {number} progress - прогресс загрузки
	 */
	handleProgress(progress) {
		if (this.OnProgress) {
			// const percentCompleted = (event.loaded / event.total) * 100;

			this.OnProgress(progress);
		}
	}

	/**
	 * Callback, вызываемый при изменении статуса загрузки.
	 * 
	 * @param {boolean} isLoading - является ли загрузка активной
	 */
	handleLoad(isLoading) {
		if (this.OnLoad) {
			this.OnLoad(isLoading);
		}
	}

	/**
	 * Загрузка файла на сервер.
	 * 
	 * @param {FormData} chunkData - объект FormData для загрузки
	 * @returns {Promise<Object>} - Promise, который будет выполнен, когда файлы будут загружены
	 */
	async uploadChunk(chunkData) {
		const token = this.authHelper.getToken();

		return new Promise((resolve, reject) => {
			const request = new XMLHttpRequest();

			request.open('POST', `${this.url}/uploadV2?name=${this.project.name}`);
			request.setRequestHeader('Authorization', `Bearer ${token}`);
			request.setRequestHeader('Media-Type', 'text/plain');

			request.upload.onprogress = (event) => {
				const percentCompleted = (event.loaded / event.total) * 100 * 0.6;

				this.handleProgress(percentCompleted);
			}

			request.onload = () => {
				if (request.status >= 200 && request.status < 300) {
					const result = JSON.parse(request.responseText);

					resolve(result);
				} else {
					reject(new Error(`Не удалось загрузить файлы: ${request.statusText}`));
				}
			};

			request.onerror = () => reject(new Error('Ошибка при загрузки файлов'));

			request.send(chunkData);
		});
	}

	/**
	 * Создает копию файлов на сервере для обработки МЛ
	 * 
	 * @param {string} projectID - id проекта
	 * 
	 * @returns {Promise<Object>} - результат создания копии
	 */
	async createProxy(projectID) {
		const token = this.authHelper.getToken();

		try {
			const response = await fetch(`${this.url}/createProxy/${projectID}`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});

			if (!response.ok) {
				throw new Error(response.statusText);
			}

			const result = await response.json();

			this.handleProgress(90);

			return result;
		} catch (error) {
			console.error('Ошибка при создании копии файлов:', error);
			this.uploadError('uploadError');
	
			throw error;
		}
	}

	/**
	 * Обработка проекта МЛ сервисом
	 * 
	 * @param {string} projectID - id проекта
	 * 
	 * @returns {Promise<Object>} - результат обработки МЛ сервисом
	 */
	async projectProcessing(projectID) {
		const token = this.authHelper.getToken();

		try {
			const response = await fetch(`${this.url}/align/${projectID}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`
				}
			});

			if (!response.ok) {
				throw new Error(response.statusText);
			}

			const result = await response.json();

			this.handleProgress(100);

			return result;
		} catch (error) {
			console.error('Ошибка при обработке проекта ML сервисом:', error);
			this.uploadError('uploadError');

			throw error;
		}
	}

	/**
	 * Создает проект, загружает видео и субтитры, обрабатывает проект ML сервисом
	 * 
	 * @param {File[]} files - массив файлов, которые будут загружены
	 * 
	 * @returns {Promise<Object>} - результат создания проекта
	 */
	async createUpload(files) {
		this.handleLoad(true);

		const chunkData = this.createFormData(files);

		try {
			this.uploadMessage('uploadChunk');
			const data = await this.uploadChunk(chunkData);

			if (data.id) {
				this.uploadMessage('uploadProxy');
				const proxy = await this.createProxy(data.id);
	
				if (proxy) {
					this.uploadMessage('uploadML');
					const result = await this.projectProcessing(data.id);
	
					if (result) this.OnComplete(data);
				}
			}
		} catch (error) {
			if (this.OnComplete) this.OnComplete(null);

			this.uploadError('uploadError');
			console.error(error);
		} finally {
			this.uploadMessage('');
			this.handleLoad(false);
		}
	}
}


export default UploadClient;