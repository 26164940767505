import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { projectVideosInventory } from '../widgets/newProject/newProjectForm/module.data';

const useUploadVideo = ({
	files,
	setFiles,
	preset,
	setPreset,
	setErrorMessage,
	uploadError,
	setUploadError,
}) => {
	const [projectVideos, setProjectVideos] = useState(filterProjectVideos(0));

	const { t } = useTranslation();

	const handleOnChange = (newFiles, id) => {
		setFiles((prevState) => {
			const newMap = new Map(prevState);
			const existingFiles = newMap.get(id) || new DataTransfer().files;

			const dataTransfer = new DataTransfer();

			Array.from(existingFiles).forEach((file) => {
				dataTransfer.items.add(file);
			});

			Array.from(newFiles).forEach((file) => {
				dataTransfer.items.add(file);
			});

			newMap.set(id, dataTransfer.files);

			return newMap;
		});
	};

	const handleRemoveFile = (fileName, id) => {
		setFiles((prevFiles) => {
			const newMap = new Map(prevFiles);
			const currentFiles = newMap.get(id);

			if (!currentFiles) return prevFiles;

			const dataTransfer = new DataTransfer();

			Array.from(currentFiles).forEach((file) => {
				if (file.name !== fileName) {
						dataTransfer.items.add(file);
				}
			});

			if (dataTransfer.files.length > 0) {
				newMap.set(id, dataTransfer.files);
			} else {
				newMap.delete(id);
			}

			return newMap;
		});
	};

	const validateFiles = () => {
		const errors = {};
		let isValid = true;

		projectVideos.forEach(video => {
			if (!files.has(video.camera) || files.get(video.camera).length === 0) {
				errors[video.camera] = t("oneOreMore");
				isValid = false;
			}
		});

		setErrorMessage(errors);
		return isValid;
	};

	function filterProjectVideos(presetIdx) {
		const res = [];

		for(const pv of projectVideosInventory.slice(0, presetIdx + 1)) {
			const clone = {...pv};
			res.push(clone);
		}

		return res;
	};

	const doSetPreset = (presetIdx) => {
		const filtered = filterProjectVideos(presetIdx);

		setProjectVideos(filtered);
		setPreset(presetIdx);
	};

	const handleAddVideo = () => {
		if (preset >= 2) return;
		if (uploadError) setUploadError('');

		doSetPreset(preset + 1);
	};

	const handleRemoveUpload = (id) => {
		setFiles((prevFiles) => {
			const newMap = new Map(prevFiles);
			newMap.delete(id);
			
			return newMap;
		});
	};

	const handleRemoveVideo = (id) => {
		if (preset <= 0) return;
		if (uploadError) setUploadError('');

		handleRemoveUpload(id);
		doSetPreset(preset - 1);
	};

	return {
		projectVideos,
		handleOnChange,
		handleRemoveFile,
		validateFiles,
		handleAddVideo,
		handleRemoveVideo
	};
};

export default useUploadVideo;