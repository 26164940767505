import Card from '../../../components/card';

function SubscriptionInfo() {
	return (
		<Card>
			<div className="d-flex flex-column gap-3 m-4">
				{/* <ArrowLeft
					className="position-absolute top-0 start-0 m-3"
					role='button'
					width={24}
					height={24}
					onClick={() => navigate('/')}
				/> */}
				<h3 className="fw-bold">
					Бесплатный доступ к сервису по заявке:
				</h3>
				<p className="fs-6 m-0">
					Мы в течении года поддерживали полностью бесплатный доступ к сервису.
					В скором времени мы вводим платные тарифы для использования сервиса.
				</p>
				<p className="fs-6 m-0">
					Пока мы находимся на этапе технической подготовки, при этом оставляем возможность бесплатно протестировать сервис.
				</p>
				<p className="fs-6 m-0">
					Вы можете получить тестовый период.
					<br />
					Для этого можете написать нам в
					<a
						className="text-decoration-none text-info ms-1"
						target="_blank"
						rel="noreferrer"
						href='https://t.me/alexnik4off'
					>
						Telegram
					</a>.
				</p>
			</div>
		</Card>
	);
}

export default SubscriptionInfo;
