import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonSubmit } from '../../components/button/ButtonSubmit';
import Button from 'react-bootstrap/Button';

import { ProjectContext } from '../../context/projectContext';

function ProjectButtons() {
	const { t } = useTranslation();

	const context = useContext(ProjectContext);

	const { project, videoSource, handlePreview, removeProject } = context?.render;
	const { subtitleRender, subtitleLoader, addSubtitles } = context?.subtitle;
	const { isLoadingFiles, createXmlAndDownload } = context?.xml;
	const {
		silenceLoader,
		cutSilenceLoader,
		addSilenceCutting,
		addSilenceAccelerator
	} = context?.silence;

	return (
		<>
			{
				project?.status === 4 && (
					<div className="d-flex justify-content-around flex-wrap gap-3 btn-container mt-3">
						{
							!subtitleRender &&
								<Button
									className="btn btn-width"
									variant={"outline-info"}
									size="lg"
									onClick={addSubtitles}
									disabled={subtitleLoader}
								>
									{subtitleLoader ? t("loading") : t("subtitles")}
								</Button>
						}
						<Button
							className="btn btn-width"
							variant={"outline-info"}
							size="lg"
							onClick={addSilenceAccelerator}
							disabled={silenceLoader}
						>
							{silenceLoader ? t("loading") : t("silenceAccelerator")}
						</Button>
						<Button
							className="btn btn-width"
							variant={"outline-info"}
							size="lg"
							onClick={addSilenceCutting}
							disabled={cutSilenceLoader}
						>
							{cutSilenceLoader ? t("loading") : t("silenceCutting")}
						</Button>
						<Button
							className="btn btn-width"
							variant={"outline-info"}
							size="lg"
							onClick={createXmlAndDownload}
							disabled={isLoadingFiles}
						>
							{isLoadingFiles ? t("loading") : t("xml")}
						</Button>
					</div>
				)
			}
			{
				project?.status !== 3 && (
					<div className="d-flex justify-content-around flex-wrap gap-3 btn-container mt-3">
						<ButtonSubmit text={t("beginRender")}></ButtonSubmit>
						{
							videoSource?.length > 0 ? (
								<Button
									className="btn btn-width"
									variant={"outline-info"}
									size="lg"
									onClick={handlePreview}
								>
									{t("createPreview")}
								</Button>
								) : null
						}
						<Button
							className="btn btn-width"
							variant={"outline-danger"}
							size="lg"
							onClick={removeProject}
						>
							{t("delete")}
						</Button>
					</div>
				)
			}
		</>
	);
}

export default ProjectButtons;
